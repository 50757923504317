<template>
  <v-app-bar
    dark
    color="secondary"
  >
    <!-- MOBILE -->
    <v-app-bar-title>
      <AppLink
        :disabled="$route.path === '/'"
        to="/"
      >
        Salsa
      </AppLink>
    </v-app-bar-title>
    <v-spacer />

    <template v-if="auth.isLogged">
      <v-app-bar-title>
        {{ auth.user?.email }}
      </v-app-bar-title>

      <v-btn
        icon
        :to="localePath('account')"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="auth.logout"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>

    <v-btn
      v-else
      icon
      :to="localePath('auth')"
    >
      <v-icon>mdi-login</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script setup lang="ts">
const auth = useStoreAuth()
const localePath = useLocalePath()
</script>
