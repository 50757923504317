<template>
  <v-app>
    <AppHeader v-if="!isRoot" />

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
const isRoot = computed(() => {
  const route = useRoute()
  if (!route.name) return route.path === '/'

  const [name, _lang] = String(route.name).split('___')
  return name === 'index'
})
</script>
